import React from 'react';

import { Link } from 'react-router-dom';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default ({ tabs }) => {
    useStyles(classes, globalStyles);
    
    return (
        <div className={`${classes.container} ${globalStyles.flex}`}>
            <ul className={classes.tabs}>
                {
                    tabs.map((tab, index) => (
                        <li className={tab.active ? `${classes.tab} ${classes.active}` : classes.tab} key={index} >

                            {
                                tab.serverSide
                                    ? <Link className={classes.link} to={tab.url}>{tab.title}</Link>
                                    : <a className={classes.link} href={tab.url}>{tab.title}</a>
                            }
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}