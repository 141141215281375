const getTabs = (country, t, types) => {
    return country.url.toLowerCase() === 'ar'
        ? [
            { title: t('personalPurchase'), active: types.personal.active, url: types.personal.url, serverSide: true }, 
            { title: t('electronicPurchase'), active: types.electronic.active, url: types.electronic.url, serverSide: true }, 
            { title: t('mobilePurchase'), active: types.mobile.active, url: types.mobile.url, serverSide: true }
          ]
        : [
            { title: t('electronicPurchase'), active: types.electronic.active, url: types.electronic.url, serverSide: true }, 
            { title: t('mobilePurchase'), active: types.mobile.active, url: types.mobile.url, serverSide: true }
          ];
};

export { getTabs }